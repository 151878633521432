import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { BodyMedium } from '@components/styled/Typography';
import IconWrapperComponent from '@shared/ui/icons/Icon';
import { ReactComponent as EllipseIcon } from './ellipse.svg';
const Container = styled.div(() => ({}));
const TitleContainer = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    gap: `${props.theme.spacing_sizes.m}px`,
    marginBottom: `${props.theme.spacing_sizes.xs * 1.75}px`,
}));
const Title = styled.span(props => ({
    display: 'flex',
    alignItems: 'center',
    gap: `${props.theme.spacing_sizes.s}px`,
    fontSize: `${props.theme.spacing_sizes.xs * 2}px`,
    fontWeight: 600,
    lineHeight: `${props.theme.spacing_sizes.xs * 3.25}px`,
}));
const Text = styled(BodyMedium)(props => ({
    lineHeight: `${props.theme.spacing_sizes.xs * 3.25}px`,
}));
const IconWrapper = styled(IconWrapperComponent)(props => ({
    position: 'absolute',
    top: '15%',
    left: '25%',
    path: {
        fill: `${props.theme.palette.primary.main}`,
    },
}));
const IconWithShade = styled.span(() => ({
    display: 'flex',
    position: 'relative',
}));
const IconComponentWrapper = ({ IconComponent = undefined, keepOriginalIconLook = false, }) => {
    if (!IconComponent) {
        return null;
    }
    if (keepOriginalIconLook) {
        return (_jsx(IconWrapperComponent, { size: 18, IconComponent: IconComponent, keepOriginalColor: true }));
    }
    return (_jsxs(IconWithShade, { children: [_jsx(EllipseIcon, {}), _jsx(IconWrapper, { IconComponent: IconComponent })] }));
};
const NameCell = ({ title = undefined, Icon = undefined, text = undefined, keepOriginalIconLook = false, }) => (_jsxs(Container, { children: [_jsxs(TitleContainer, { children: [_jsx(IconComponentWrapper, { IconComponent: Icon, keepOriginalIconLook: keepOriginalIconLook }), _jsx(Title, { children: title || '' })] }), _jsx(Text, { children: text || '' })] }));
export default NameCell;
