import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useToastNotification } from '@shared/ui/overlay/Toast';
import { getExchangeSvgIcon } from '@shared/ui/icons/ExchangeIcon';
import { ReactComponent as DeleteIcon } from '@icons/wolfkit-light/trash-light.svg';
import { useDeleteExchangeAccountMutation, } from '@shared/api/exchange';
import Row from '../../../../components/Row';
import NameCell from '../../NameCell';
import ValueCell from '../../ValueCell';
const ApiKeyRow = ({ exchange, }) => {
    const { t } = useTranslation();
    const { toast } = useToastNotification();
    const [deleteExchangeAccount, { isLoading, error, isError, },] = useDeleteExchangeAccountMutation();
    const handleDelete = (accountId) => {
        deleteExchangeAccount({ accountId });
    };
    if (isError) {
        toast({
            message: JSON.stringify(error),
            variant: 'error',
        });
    }
    return (_jsx(Row, { name: (_jsx(NameCell, { title: exchange.properties.connectionName, Icon: getExchangeSvgIcon(exchange.type), keepOriginalIconLook: true })), value: _jsx(ValueCell, {}), manageBtnProps: {
            color: 'error',
            startIcon: DeleteIcon,
            children: t('actions.delete', { ns: 'common' }),
            isLoading,
            onClick: () => handleDelete(exchange.id),
        } }, exchange.id));
};
export default ApiKeyRow;
