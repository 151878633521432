import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '@shared/ui/progress-loaders/LoadingIndicator';
import { ContainerColumn } from '@components/styled';
import { BodyMedium } from '@components/styled/Typography';
import { useGetExchangeAccountsQuery } from '@shared/api/exchange';
import ApiKeyRow from './ApiKeyRow';
const LoadingContainer = styled(ContainerColumn)(() => ({
    height: 'auto',
    minHeight: '320px',
}));
const Container = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
const NotFoundContainer = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    padding: `${props.theme.spacing_sizes.l}px 0`,
}));
const ApiKeysManagement = () => {
    const { t } = useTranslation();
    const { data, isLoading, } = useGetExchangeAccountsQuery();
    if (isLoading) {
        return (_jsx(LoadingContainer, { children: _jsx(LoadingIndicator, { isLoading: true, takeFullContainerSpace: true }) }));
    }
    if (data && data.length > 0) {
        return (_jsx(Container, { children: data.map((exchange) => (_jsx(ApiKeyRow, { exchange: exchange }, exchange.id))) }));
    }
    return (_jsx(NotFoundContainer, { children: _jsx(BodyMedium, { children: t('errors.not_found', { ns: 'common' }) }) }));
};
export default ApiKeysManagement;
