import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useState } from 'react';
import { useAppSelector } from '@app/store/Hooks';
import { ReactComponent as EmailIcon } from '@icons/wolfkit-light/envelope-light.svg';
import { ReactComponent as KeyIcon } from '@icons/wolfkit-light/key-light.svg';
import { ReactComponent as CameraIcon } from '@icons/wolfkit-light/camera-light.svg';
import { ContainerColumn } from '@components/styled';
import { maskEmail } from '@shared/lib/email';
import { IsDefined } from '@shared/lib/Helpers';
import ChangePassword from '../../../sidebars/ChangePassword';
import ValueCell from '../ValueCell';
import Row from '../../../components/Row';
import NameCell from '../NameCell';
import KYCValue from './KYCValue';
const Container = styled(ContainerColumn)(() => ({
    width: 'auto',
    height: 'auto',
}));
const MainSettings = () => {
    var _a, _b;
    const { t } = useTranslation();
    const { user } = useAppSelector(state => state.user);
    const [isChangePasswordSidebarOpen, setIsChangePasswordSidebarOpen] = useState(false);
    const openChangePasswordSidebar = () => {
        setIsChangePasswordSidebarOpen(true);
    };
    const closeChangePasswordSidebar = () => {
        setIsChangePasswordSidebarOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Container, { children: [_jsx(Row, { name: (_jsx(NameCell, { Icon: EmailIcon, title: t('settings.tabs.personal_info.main_table.email.title') })), value: (_jsx(ValueCell, { text: (user === null || user === void 0 ? void 0 : user.email) ? maskEmail(user === null || user === void 0 ? void 0 : user.email) : undefined })), hideButton: true }), _jsx(Row, { name: (_jsx(NameCell, { Icon: KeyIcon, title: t('settings.tabs.personal_info.main_table.password.title') })), value: _jsx(ValueCell, {}), manageBtnProps: {
                            children: t('actions.manage', { ns: 'common' }),
                            onClick: openChangePasswordSidebar,
                        } }), _jsx(Row, { name: (_jsx(NameCell, { Icon: CameraIcon, title: t('settings.tabs.personal_info.main_table.kyc.title') })), value: (_jsx(KYCValue, { isUserDefined: IsDefined(user), isVerifiedTrader: IsDefined((_a = user === null || user === void 0 ? void 0 : user.traderProfile) === null || _a === void 0 ? void 0 : _a.isVerifiedTrader) &&
                                ((_b = user === null || user === void 0 ? void 0 : user.traderProfile) === null || _b === void 0 ? void 0 : _b.isVerifiedTrader) === true })), manageBtnProps: {
                            children: t('actions.manage', { ns: 'common' }),
                        } })] }), _jsx(ChangePassword, { isOpen: isChangePasswordSidebarOpen, onClose: closeChangePasswordSidebar, shouldCloseOnOutsideClick: true, destroyOnHide: true })] }));
};
export default MainSettings;
